<template>
 <div id="page-container" class="">
  <div class="content-wrap pb-5">
   <PbxDashboard
    v-model:selector="periodSelector"
    :dataSeriesCurrentMonth="dataSeriesCurrentMonth"
    @action="getDataFromElement()"
    @button-selector="(...args) => getSelectedButton(...args)"
    :extensions="extensions"
    :dataSeriesStacked="dataSeriesStacked"
    :urlType="urlType"
    :dataSeriesConcatenated="dataSeriesConcatenated"
    :periodSelector="this.periodSelector"
    @timeSelector="setTimeSelected($event)"
    @all-selected="allSelected($event)"
    :buttonSelected="this.buttonSelected"
    :textMonth="this.textMonth"
    :timeSelected="this.timeSelected"
   />
   <div v-show="extensions.length === 0">
    <EmptyStateDashboard />
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

//const buttonSelected = $t("dashboards.month");
export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataSeriesCurrentMonth: [],
   extensions: [],
   dataSeriesStacked: [],
   dataSeriesConcatenated: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: this.$t("dashboards.month"),
   textMonth: "",
   data: undefined,
  };
 },
 methods: {
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getPbxData(stringOfElements, period, type) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${
      this.hostName
     }/origins?&elements=${stringOfElements}&days=${period}&type=${type}`,
     {
      headers: {
       Authorizations: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    console.log("file: ExpertStatistics.vue ~ line 90 ~ getPbxData ~ res.data", res.data);
    return res.data;
   } catch (error) {
    const status = error.response.status;
    this.$emit("setLoading", false);
    this.errorHandling(error);
    if (error.response.status === 500) {
     const msg = this.$t("error500");
     this.$router.push({
      name: "Error",
      query: { status, msg },
     });
    }
   }
  },
  async getDataFromElement(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   this.dataSeriesCurrentMonth = [];
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;
   let res = undefined;

   if (this.periodSelector) {
    period = this.periodSelector;
   }

   if (this.pbxElements && this.pbxElements.length > 0) {
    this.$emit("setLoading", true);
    this.dataSeriesCurrentMonth = [];
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);

    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements, period, type);
     this.data = res;
    }

    this.dataSeriesCurrentMonth = [];
    let keys = [];
    let objectKeyToGet = "";
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    obj["data"] = [];

    if (this.timeSelected.length > 0) {
     if (this.timeSelected[0].slice(-2) === "00" && this.timeSelected[1].slice(-2) === "00") {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
    }
    if (timeSelectedIsHour) {
     keys = Object.keys(res.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else if (timeSelectedIsQuarter) {
     keys = Object.keys(res.sum_quarter_hourly);
     objectKeyToGet = "sum_quarter_hourly";
    } else {
     if (period.length == 6 || period.includes("-")) {
      keys = Object.keys(res.sum_period);
      objectKeyToGet = "sum_period";
     } else {
      keys = Object.keys(res.all);
      objectKeyToGet = "all";
     }
    }

    if (keys.length > 0) {
     let dataObj = {};
     let dataObjSerie = {};
     let dataObjColor = {};

     const originElements = this.getAllOriginElementsFromKey(keys);

     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      let elementFromArray = arrayOfElements[arrIndex];

      obj = {};
      dataObj = {};

      let elementHasKey = false;

      for (let index = 0; index < keys.length; index++) {
       let splitElement = keys[index].split(":");
       if (splitElement[1] == arrayOfElements[arrIndex]) {
        elementHasKey = true;
       }
      }
      if (elementHasKey) {
       if (objectKeyToGet === "sum_period") {
        obj = this.generateDataForPeriodCharts(keys, res, elementFromArray, objectKeyToGet);
       } else if (objectKeyToGet === "sum_hourly") {
        obj = this.generateDataForHourlyCharts(
         keys,
         res,
         elementFromArray,
         objectKeyToGet,
         originElements
        );
       } else if (objectKeyToGet === "sum_quarter_hourly") {
        obj = this.generateDataForQuarterHourlyCharts(
         keys,
         res,
         elementFromArray,
         objectKeyToGet,
         originElements
        );
       } else {
        obj = this.generateDataForAllCharts(keys, res, elementFromArray, objectKeyToGet, period);
       }
      } else {
       dataObjColor = "#5B9998";
       dataObjSerie = 0;
       dataObj["No_data"] = { dataObjSerie, dataObjColor };
       obj["data"] = dataObj;
       obj["name"] = arrayOfElements[arrIndex] + " - " + res.map[arrayOfElements[arrIndex]];
      }
      this.dataSeriesCurrentMonth.push(obj);
      this.extensions.splice(arrIndex, 1, obj["name"]);
     }
     this.generateConsolidatedData(keys, res, objectKeyToGet, period, originElements);
    } else {
     let dataObj = {};
     let dataObjSerie = {};
     let dataObjColor = {};

     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      dataObjColor = "#5B9998";
      dataObjSerie = 0;
      dataObj["No_data"] = { dataObjSerie, dataObjColor };
      obj["data"] = dataObj;
      obj["name"] = arrayOfElements[arrIndex] + " - " + res.map[arrayOfElements[arrIndex]];
      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataSeriesCurrentMonth.push(obj);
     }
     this.dataSeriesConcatenated = [];
     obj = {};
     dataObjColor = "#5B9998";
     dataObjSerie = 0;
     dataObj["No_data"] = { dataObjSerie, dataObjColor };
     obj["data"] = dataObj;
     obj["name"] = "cons";
     this.dataSeriesConcatenated.push(obj);
    }
    this.$emit("setLoading", false);
   } else {
    this.dataSeriesCurrentMonth.length = 0;
    this.dataSeriesConcatenated.length = 0;
   }
   this.$emit("setLoading", false);
  },
  generateDataForQuarterHourlyCharts(keys, res, elementFromArray, objectKeyToGet, originElements) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   let elementValue = 0;

   let firsQuarterHour =
    ("0" + (parseInt(this.timeSelected[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.timeSelected[0].slice(-2);
   let lastQuarterHour =
    ("0" + (parseInt(this.timeSelected[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.timeSelected[1].slice(-2);

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    keys.forEach((element, index) => {
     let splitElement = element.split(":");
     let elementName = "";
     elementName = res.map[splitElement[1]];
     if (splitElement[1] == elementFromArray) {
      let quarterHourlyElementSlice = splitElement[2].slice(-4);
      obj["name"] = splitElement[1] + "-" + elementName;
      if (splitElement[3] === originElement) {
       if (
        parseInt(firsQuarterHour) <= parseInt(quarterHourlyElementSlice) &&
        parseInt(quarterHourlyElementSlice) < parseInt(lastQuarterHour)
       ) {
        elementValue = elementValue + res[objectKeyToGet][element];
        dataObjColor = this.getElementColor(splitElement[3]);

        dataObjSerie = elementValue;
        dataObj[splitElement[3]] = {
         dataObjSerie,
         dataObjColor,
        };
       }
       obj["data"] = dataObj;
      }
     }
    });
   }
   return obj;
  },
  generateDataForHourlyCharts(keys, res, elementFromArray, objectKeyToGet, originElements) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   let elementValue = 0;
   let rangeOfHours = [];
   let rangeOfHoursLenght =
    parseInt(this.timeSelected[1].slice(0, 2)) - parseInt(this.timeSelected[0].slice(0, 2));
   let firsHour = (
    "0" +
    (parseInt(this.timeSelected[0].slice(0, 2)) + parseInt(this.hostTimeZone))
   ).slice(-2);

   for (let index = 0; index <= rangeOfHoursLenght; index++) {
    rangeOfHours.push(("0" + (parseInt(firsHour) + index)).slice(-2));
   }

   for (let index = 0; index < originElements.length; index++) {
    elementValue = 0;
    const originElement = originElements[index];
    keys.forEach((element, index) => {
     let splitElement = element.split(":");
     let elementName = "";
     elementName = res.map[splitElement[1]];
     if (splitElement[1] == elementFromArray) {
      obj["name"] = splitElement[1] + "-" + elementName;
      if (splitElement[3] === originElement) {
       if (
        parseInt(rangeOfHours[0]) <= parseInt(splitElement[2].slice(-2)) &&
        parseInt(splitElement[2].slice(-2)) < parseInt(rangeOfHours[rangeOfHours.length - 1])
       ) {
        elementValue = elementValue + res[objectKeyToGet][element];
        dataObjColor = this.getElementColor(splitElement[3]);

        dataObjSerie = elementValue;
        dataObj[splitElement[3]] = {
         dataObjSerie,
         dataObjColor,
        };
       }
       obj["data"] = dataObj;
      }
     }
    });
   }
   return obj;
  },
  generateDataForPeriodCharts(keys, res, elementFromArray, objectKeyToGet) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   keys.forEach((element, index) => {
    let splitElement = element.split(":");
    let elementName = "";
    elementName = res.map[splitElement[1]];
    if (splitElement[1] == elementFromArray) {
     obj["name"] = splitElement[1] + " - " + elementName;

     dataObjColor = this.getElementColor(splitElement[3]);

     dataObjSerie = res[objectKeyToGet][element];

     dataObj[splitElement[3]] = { dataObjSerie, dataObjColor };
     obj["data"] = dataObj;
    }
   });
   return obj;
  },
  generateDataForAllCharts(keys, res, elementFromArray, objectKeyToGet, period) {
   let obj = {};
   let dataObj = {};
   let dataObjSerie = {};
   let dataObjColor = {};
   keys.forEach((element, index) => {
    let splitElement = element.split(":");
    let elementName = "";
    elementName = res.map[splitElement[1]];
    if (splitElement[2] === period) {
     if (splitElement[1] == elementFromArray) {
      obj["name"] = splitElement[1] + " - " + elementName;

      dataObjColor = this.getElementColor(splitElement[3]);

      dataObjSerie = res[objectKeyToGet][element];

      dataObj[splitElement[3]] = {
       dataObjSerie,
       dataObjColor,
      };
      obj["data"] = dataObj;
     }
    }
   });
   return obj;
  },
  generateConsolidatedData(keys, res, objectKeyToGet, period, originElements) {
   this.dataSeriesConcatenated = [];

   let objConcat = {};

   let elementFromArray = "cons";

   if (objectKeyToGet === "sum_period") {
    objConcat = this.generateDataForPeriodCharts(keys, res, elementFromArray, objectKeyToGet);
   } else if (objectKeyToGet === "sum_hourly") {
    objConcat = this.generateDataForHourlyCharts(
     keys,
     res,
     elementFromArray,
     objectKeyToGet,
     originElements
    );
   } else if (objectKeyToGet === "sum_quarter_hourly") {
    objConcat = this.generateDataForQuarterHourlyCharts(
     keys,
     res,
     elementFromArray,
     objectKeyToGet,
     originElements
    );
   } else {
    objConcat = this.generateDataForAllCharts(keys, res, elementFromArray, objectKeyToGet, period);
   }

   this.dataSeriesConcatenated.push(objConcat);
  },
  getAllOriginElementsFromKey(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    let splitEl = element.split(":");
    arrayOfElements.push(splitEl[3]);
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
  getElementColor(type) {
   console.log("file: ExpertStatistics.vue ~ line 443 ~ getElementColor ~ type", type);
   let color = "";
   if (type === "queue") {
    color = "#97BAA6";
   } else if (type === "extension") {
    color = "#14233C";
   } else if (type === "world") {
    color = "#E34B5F";
   } else if (type === "ivr") {
    color = "#194E63";
   } else if (type === "external_number_success") {
    color = "#1E7889";
   } else if (type === "call_flow") {
    color = "#D9B3A3";
   } else if (type === "voicemail") {
    color = "#EFB0A1";
   }
   return color;
  },
  getElementsType(data) {
   let array = [];
   let keys = Object.keys(data);
   keys.forEach((element, index) => {
    let splitElement = element.split(":");
    if (!array.includes(splitElement[3])) {
     array.push(splitElement[3]);
    }
   });
   return array;
  },
 },
 watch: {
  periodSelector: function (val) {
   this.getDataFromElement();
  },
  timeSelected: function (val) {
   this.getDataFromElement(true);
  },
 },
 mounted() {
  if (!this.periodSelector && !this.pbxPeriodSelected) {
   this.periodSelector = this.currentMonthAndYearForPbx;
  }
  if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.pbxElementType !== this.urlType) this.$store.dispatch("pbxElements", null);
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
  ]),
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
